<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="videoBox">
      <video id="bgVideo" muted autoplay loop playsinline>
        <source src="../../../assets/images/pc/bg.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="logo"></div>
    <div class="contactInfo">
      <div class="contactItem telegram" @click="toTg"></div>
      <!-- <div class="contactItem potato" @click="toPotato"></div> -->
      <div class="contactItem officialWebsite" @click="jumpOfficialWeb"></div>
    </div>
    <div class="qrcodeBox">
      <img class="qrcode" :src="qrImg" alt="" />
    </div>
    <div class="btnBox">
      <div class="androidBtn"></div>
      <div class="iosBtn"></div>
    </div>
  </div>
</template>
<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg", "os"],
  data() {
    return {};
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关
    const video = document.getElementById("bgVideo");
    video.addEventListener("loadedmetadata", function() {
      if (video.buffered.length === 0) return;
      var bufferedSeconds = video.buffered.end(0) - video.buffered.start(0);
      console.log(bufferedSeconds + " seconds of video are ready to play!");
    });
  },
  methods: {
    getApkInfo(type) {
      this.$emit("getApkInfo", type);
    },
    //tg
    toTg() {
      window.location.href = "https://t.me/yaojingdm1";
    },
    toPotato() {
      window.location.href = "https://ptcc.in/yaojingdm1";
    },
    toTwitter() {
      window.location.href = "https://twitter.com/YaoJingDM9";
    },
    jumpOfficialWeb() {
      window.open("https://erosm.co");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  .videoBox {
    position: absolute;
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
  .logo {
    width: 446px;
    height: 120px;
    background: url("../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 59px;
    left: 73px;
  }
  .contactInfo {
    position: absolute;
    top: 59px;
    right: 70px;
    display: flex;
    .contactItem {
      width: 68px;
      height: 68px;
    }
    .telegram {
      background: url("../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 37px;
    }
    .potato {
      background: url("../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 37px;
    }
    .officialWebsite {
      background: url("../../../assets/images/pc/officialWebsite.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .qrcodeBox {
    width: 172px;
    height: 172px;
    position: absolute;
    bottom: 90px;
    left: 73px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .qrcode {
      height: 168px;
      width: 168px;
      background: #fff;
    }
  }
  .btnBox {
    position: absolute;
    bottom: 90px;
    left: 271px;
    height: 172px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .androidBtn {
      width: 300px;
      height: 60px;
      background: url("../../../assets/images/pc/androidBtn.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 21px;
    }
    .iosBtn {
      width: 300px;
      height: 60px;
      background: url("../../../assets/images/pc/iosBtn.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
